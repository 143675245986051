import {Component, Inject} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
} from '@angular/material/dialog'

export interface WaitDialogData {
  title: string
  message: string[]
  error?: string

  // Show the close button, default we do not.
  close?: boolean
}

@Component({
  selector: 'spb-wait-dialog',
  template: `
    <span matDialogTitle>{{ data.title }}</span>

    @for (message of data.message; track $index) {
      <div matDialogContent>
        <p>{{ message }}</p>
      </div>
    }

    @if (data.error) {
      <p>{{ data.error }}</p>
    }

    @if (data.close) {
      <div matDialogActions>
        <button mat-raised-button matDialogClose="" color="primary">Stäng
        </button>
      </div>
    }`,
  imports: [MatDialogTitle, MatDialogContent, MatDialogActions, MatButton, MatDialogClose]
})
export class WaitDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: WaitDialogData) {
  }
}
